<template>
  <div class="account-avatar">
    <esmp-user-avatar
      class="account-avatar__image"
      :username="username"
      :image-url="imageUrl"
    />

    <template v-if="editable">
      <div class="account-avatar__backdrop">
        <p>Загрузить фото</p>
        <span>Формат jpg или png. Максимальный размер файла 5 МБ.</span>
      </div>

      <button
        class="account-avatar__edit-button"
        type="button"
      >
        <esmp-icon name="edit" />
      </button>

      <input
        class="account-avatar__input-file"
        type="file"
        accept="image/*"
        ref="inputFile"
        @change="onImageUpload"
      >
    </template>
  </div>
</template>

<script>
export default {
  name: 'AccountAvatar',

  props: {
    username: {
      type: String,
      required: true,
    },

    imageUrl: {
      type: String,
      default: null,
    },

    editable: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    onImageUpload(e) {
      console.log(e.target.files[0]);
    },
  },
};
</script>

<style lang="scss">
.account-avatar {
  $parentClass: &;

  position: relative;
  width: 140px;
  height: 140px;

  &__backdrop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: $color-black-op-50;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    color: $color-white;
    text-align: center;
    padding: 15px;
    opacity: 0;
    transition: opacity $base-animation-time $base-animation-function;

    p {
      color: inherit;
      border-bottom: 1px solid currentColor;
      @extend .typo-body-m !optional;
      margin-bottom: 3px;
    }

    span {
      font-size: 10px;
      line-height: 12px;
    }
  }

  &__edit-button {
    position: absolute;
    top: 4px;
    right: 4px;
    border-radius: $base-border-radius;
    border: none;
    cursor: pointer;
    outline: none;
    background: $color-white;
    padding: 4px;
    color: $color-grayscale-60;
    box-shadow: $base-shadow-l;
    transition: color $base-animation-time $base-animation-function;
  }

  &__image {
    width: 140px;
    height: 140px;
  }

  &:hover {
    #{$parentClass}__backdrop {
      opacity: 1;
    }

    #{$parentClass}__edit-button {
      color: $color-primary-1-day;
    }
  }

  &__input-file {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    cursor: pointer;
  }
}
</style>
