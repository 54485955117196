<template>
  <div class="account-main-info accent-block">
    <div class="account-main-info__wrapper">
      <h2 class="account-main-info__name">
        {{ accountMainInfo.fullName }}
      </h2>

      <div class="account-main-info__inner">
        <div class="account-main-info__block account-main-info__block--first">
          <div class="account-main-info__block-title typo-caption">
            Должность
          </div>

          <div class="account-main-info__block-caption typo-accent-m">
            {{ accountMainInfo.appointmentName }}
          </div>
        </div>

        <div class="account-main-info__block account-main-info__block--second">
          <div class="account-main-info__block-title typo-caption">
            Подразделение
          </div>

          <div class="account-main-info__block-caption typo-accent-m">
            {{ accountMainInfo.corpStructure }}
          </div>
        </div>
      </div>
    </div>

    <account-avatar
      class="account-main-info__avatar"
      :image-url="accountMainInfo.photoURL"
      :username="accountMainInfo.fullName"
      :editable="dataIsEditable"
    />
  </div>
</template>

<script>
import AccountAvatar from './AccountAvatar.vue';

export default {
  name: 'AccountMainInfo',

  components: { AccountAvatar },

  inject: ['dataIsEditable'],

  props: {
    accountMainInfo: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.account-main-info {
  padding: 32px;
  display: flex;
  justify-content: space-between;

  @include for-size(phone-portrait-down) {
    padding: 20px;
    border-radius: 12px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
  }

  &__inner {
    display: flex;

    @include for-size(phone-landscape-down) {
      flex-direction: column;
    }
  }

  &__block {

    &--first {
      min-width: 225px;
      margin-right: 24px;

      @include for-size(phone-landscape-down) {
        margin-right: 0;
        margin-bottom: 24px;
      }
      @include for-size(phone-portrait-down) {
        margin-bottom: 16px;
        min-width: unset;
      }
    }
    &--second {
      max-width: 535px;
    }
  }

  &__name {
    font-size: 28px;
    line-height: 32px;
    color: $color-white;
    margin-bottom: 24px;

    @include for-size(phone-portrait-down) {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 16px;
    }
  }

  &__block-title {
    color: $color-white-op-75;
    margin-bottom: 8px;
  }

  &__block-caption {
    @include for-size(phone-portrait-down) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__avatar {
    .account-avatar__image {
      @include for-size(phone-portrait-down) {
        width: 72px !important;
        height: 72px !important;
        font-size: 18px !important;
      }
    }
  }
}
</style>
